
export const getParams = (key) => {
    let search = window.location.search.replace(/^\?/, "");
    let pairs = search.split("&");
    let paramsMap = pairs.map(pair => {
        let [key, value] = pair.split("=");
        return [decodeURIComponent(key), decodeURIComponent(value)];
    }).reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
    return paramsMap[key] || "";
}

export const ENV_PRO = 'http://home.qunweipai.cn/#/'
export const ENV_TEST = 'http://test-home.qunweipai.cn/#/'