<template>
  <div class="content">
    <p class="login-text">星辰大海</p>
    <div class='forms-attr'>
      <a-form-model :model='form' ref='loginforms' :rules='rules' :form="form" v-bind="layout">
        <a-form-model-item label='用户名' prop='userName'>
          <a-input v-model="form.userName" @keyup.enter="handeSubmit">
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label='密码' prop='passWord'>
          <a-input-password v-model="form.passWord" @keyup.enter="handeSubmit">
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
      </a-form-model>
      <a-button @click="handeSubmit" type="primary" block>登录</a-button>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import {ENV_DEV, ENV_PRO, ENV_TEST, getParams} from "@/views/login/_url";
export default {
    data(){
      return {
        form:{
          userName:'',
          passWord:'',
          code: '',
          sign: ''
        },
        layout:{
          labelCol: { span: 3 },//标签占5份  共24份
          wrapperCol: { span:21 },
        },
        rules:{
          userName:[{ required: true, message: 'please enter your username', trigger: 'blur' }],
          passWord:[{ required: true, message: 'please enter your password', trigger: 'blur' }],
        },
      }
    },
    created() {
      try {
        const sign = getParams('sign')
        const code = getParams('code')
        if (sign && code) {
          this.form.sign = sign
          this.form.code = code
          this.submit(this.form)
        }
      } catch (e) {}
    },
  methods:{
    ...mapMutations(['updateUserInfo']),
    handeSubmit(){
      this.$refs.loginforms.validate(valid => {
        if(valid){
          this.submit(this.form)
        }else{
          return false;
        }
      });
    },
    async submit(forms){
      try {
        const res = await this.axios.post('/platform/platformaccount/platformLogin',forms)
          if(res.status == '200'){
            if (res.data.status === 'toWxAuth') {
              window.location.href = res.data.wxAuthUrl
            } else {
              this.$message.success(res.message)
              localStorage.setItem('token',res.data.token)
              localStorage.setItem('userInfo',JSON.stringify(res.data.data))
              this.updateUserInfo(res.data.data)
              if (['dev', 'local'].includes(process.env.NODE_ENV)) {
                 await this.$router.push('/cms')
              } else {
                window.location.href = process.env.NODE_ENV === 'production' ? ENV_PRO + 'cms' : ENV_TEST + 'cms'
              }
            }
          } else {
            if (['dev', 'local'].includes(process.env.NODE_ENV)) {
              await this.$router.push('')
            } else {
              window.location.href = process.env.NODE_ENV === 'production' ? ENV_PRO : ENV_TEST
            }
          }
      }catch(e){}
    }
  }
}
</script>

<style lang='scss' scoped>
.content{width:100%;height:100%;background:rgb(171,59,58);display:flex;justify-content:center;align-items:center;flex-direction:column;
    .login-text{font-size:20px;color:#fff;font-weight: bold;}
    .forms-attr{padding:40px 10px;background:#fff;border-radius:5px;width:500px;}
}
</style>